import React from "react";
import { Page } from "../components/styles";
import { graphql } from "gatsby";
import Article from "../components/article";
import { device } from "../utils/utils";
import LinksContainer from "../components/linksContainer";
import styled from "styled-components";

const GridWrapper = styled.div`
  display: grid;
  grid-template-columns: min-content 1fr;
  margin-top: 6rem;

  @media ${device.tablet} {
    grid-template-columns: repeat(1, 1fr);
    margin-top: 2rem;
  }
`;

const PressGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: masonry;
  grid-gap: 2rem;
  margin: 1rem auto;
  max-width: 1200px;
  padding: 1rem;
  article {
    display: inline-block;
    margin-bottom: 2rem;
  }

  @media ${device.laptopL} {
    grid-template-columns: repeat(2, 1fr);
  }
  @media ${device.laptop} {
    grid-template-columns: repeat(1, 1fr);
  }
`;

const Press = ({ data }) => {
  const { nodes: press } = data.allSanityPress;
  return (
    <Page>
      <GridWrapper>
        <LinksContainer />
        <PressGrid>
          {press.map((article) => (
            <Article key={article.id} article={article} />
          ))}
        </PressGrid>
      </GridWrapper>
    </Page>
  );
};

export default Press;

export const query = graphql`
  {
    allSanityPress {
      nodes {
        image {
          asset {
            fluid {
              ...GatsbySanityImageFluid
            }
          }
        }
        title
        source
        subtitle
        link
        id
      }
    }
  }
`;
