import React from "react";
import Img from "gatsby-image";
import MyLink from "./mylink";
import styled from "styled-components";
import { border, device } from "../utils/utils";
import { fadeUp } from "../components/styles";

const ArticleWrapper = styled.article`
  ${fadeUp}
  max-width:400px;

  @media ${device.tablet} {
    max-width: 100%;
  }
  img {
    border-radius: 5px;
  }
  footer {
    margin-top: 1rem;
  }
  h3 {
    border-bottom: ${border};
    border-color: ${(props) => props.theme.stoneBlack};
    line-height: 1.5;
    padding-bottom: 1rem;
    span {
      a {
        margin-left: 0.5rem;
        text-transform: none;
      }
    }
  }
  p {
    margin-top: 1rem;
    line-height: 1.5;
  }
`;

const Article = ({ article }) => {
  return (
    <ArticleWrapper>
      <Img fluid={article.image.asset.fluid} alt={article.title} />
      <footer>
        <h3>
          <MyLink to={article.link} inline>
            {article.title}
          </MyLink>
          <span>
            <a href={article.link} target="_blank" rel="noreferrer">
              {article.source}
            </a>
          </span>
        </h3>
        <p>{article.subtitle}</p>
      </footer>
    </ArticleWrapper>
  );
};

export default Article;
